const tabs = {
    get_started: 'Начало',
    dashboard: 'Панель управления',
    applications: 'Приложения',
    api_resources: 'Ресурсы API',
    sign_in_experience: 'Опыт входа в систему',
    connectors: 'Коннекторы',
    webhooks: 'Webhooks',
    /** UNTRANSLATED */
    organizations: 'Organizations',
    users: 'Управление пользователями',
    audit_logs: 'Журналы аудита',
    roles: 'Роли',
    docs: 'Документация',
    tenant_settings: 'Настройки',
    /** UNTRANSLATED */
    mfa: 'Multi-factor auth',
};
export default Object.freeze(tabs);
