@use '@/scss/underscore' as _;

.rolesTable {
  flex: 1;
  margin-bottom: _.unit(6);
  color: var(--color-text);

  .filter {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .searchInput {
      width: 306px;
    }
  }

  .description {
    @include _.text-ellipsis;
  }
}

.icon {
  width: 40px;
  height: 40px;
  margin-right: _.unit(2);
}
