@use '@/scss/underscore' as _;

.select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 _.unit(2) 0 _.unit(3);
  background: var(--color-layer-1);
  border: 1px solid var(--color-border);
  border-radius: 8px;
  outline: 3px solid transparent;
  transition-property: outline, border;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  font: var(--font-body-2);
  cursor: pointer;
  position: relative;

  &.multiple {
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: _.unit(2);
    padding: _.unit(1.5) _.unit(3);
    cursor: text;

    .tag {
      cursor: auto;
      display: flex;
      align-items: center;
      gap: _.unit(1);
    }

    .close {
      width: 16px;
      height: 16px;
    }

    .delete {
      width: 20px;
      height: 20px;
      margin-right: _.unit(-0.5);
    }

    input {
      color: var(--color-text);
      font: var(--font-body-2);
      background: transparent;
      flex-grow: 1;
      padding: _.unit(0.5);

      &::placeholder {
        color: var(--color-placeholder);
      }
    }
  }

  .title {
    @include _.text-ellipsis;
  }

  &.open {
    border-color: var(--color-primary);
    outline-color: var(--color-focused-variant);
  }

  &.readOnly {
    background: var(--color-inverse-on-surface);
    color: var(--color-text);
    border-color: var(--color-border);
    cursor: default;
  }

  &.error {
    border-color: var(--color-error);

    &.open {
      outline-color: var(--color-danger-focused);
    }
  }

  .icon {
    display: flex;
    margin-left: _.unit(1);
    color: var(--color-text-secondary);
  }

  .clear {
    display: none;
  }

  .arrow {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  &.clearable:hover {
    .clear {
      display: block;
    }

    .arrow {
      display: none;
    }
  }

  &.small {
    height: 30px;
  }

  &.medium {
    height: 32px;
  }

  &.large {
    height: 36px;
  }
}

.dropdown {
  padding: _.unit(1);
  max-height: 288px;
}

.spinner {
  color: var(--color-placeholder);
  margin: _.unit(2);
}

.noResult {
  color: var(--color-placeholder);
  font: var(--font-body-2);
  padding: _.unit(2);
}

.searchInputContainer {
  display: flex;
  align-items: center;

  .search {
    margin-right: _.unit(2);
    color: var(--color-text-secondary);
    width: 20px;
    height: 20px;
  }

  .searchInput {
    height: 100%;
    width: 100%;
    appearance: none;
    color: var(--color-text);
    font: var(--font-body-2);
    background: transparent;
    padding: 0;

    &::placeholder {
      color: var(--color-placeholder);
    }
  }
}
