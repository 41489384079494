const tabs = {
    get_started: 'Iniziare',
    dashboard: 'Dashboard',
    applications: 'Applicazioni',
    api_resources: 'Risorse API',
    sign_in_experience: 'Esperienza di accesso',
    connectors: 'Connettori',
    webhooks: 'Webhooks',
    /** UNTRANSLATED */
    organizations: 'Organizations',
    users: 'Gestione utenti',
    audit_logs: 'Registri di verifica',
    roles: 'Ruoli',
    docs: 'Documenti',
    tenant_settings: 'Impostazioni',
    /** UNTRANSLATED */
    mfa: 'Multi-factor auth',
};
export default Object.freeze(tabs);
