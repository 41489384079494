const general = {
    placeholder: 'Заполнитель',
    submit: 'Отправить',
    skip: 'Пропустить',
    next: 'Следующий',
    back: 'Назад',
    retry: 'Повторить попытку',
    done: 'Готово',
    search: 'Поиск',
    search_placeholder: 'Поиск',
    clear_result: 'Очистить результаты',
    save: 'Сохранить',
    save_changes: 'Сохранить изменения',
    saved: 'Сохранено',
    discard: 'Отказаться',
    loading: 'Загрузка...',
    redirecting: 'Перенаправление...',
    add: 'Добавить',
    added: 'Добавлено',
    cancel: 'Отмена',
    confirm: 'Подтвердить',
    check_out: 'Оформить заказ',
    create: 'Создать',
    set_up: 'Настроить',
    customize: 'Настроить',
    enable: 'Включить',
    reminder: 'Напоминание',
    delete: 'Удалить',
    more_options: 'Дополнительные опции',
    close: 'Закрыть',
    copy: 'Копировать',
    copying: 'Копирование',
    copied: 'Скопировано',
    required: 'Обязательное',
    add_another: 'Добавить ещё',
    /** UNTRANSLATED */
    create_another: 'Create another',
    deletion_confirmation: 'Вы действительно хотите удалить этот {{title}}?',
    settings_nav: 'Настройки',
    unsaved_changes_warning: 'Вы внесли изменения. Вы действительно хотите покинуть эту страницу?',
    leave_page: 'Покинуть страницу',
    stay_on_page: 'Остаться на странице',
    type_to_search: 'Введите для поиска',
    got_it: 'Понятно',
    continue: 'Продолжить',
    page_info: '{{min, number}}-{{max, number}} из {{total, number}}',
    learn_more: 'Узнать больше',
    tab_errors: '{{count, number}} ошибок',
    skip_for_now: 'Пропустить',
    remove: 'Удалить',
    visit: 'Посетить',
    join: 'Присоединиться',
    try_now: 'Попробуйте сейчас',
    multiple_form_field: '(Несколько)',
    demo: 'Демо',
    unnamed: 'Без названия',
    view: 'Просмотр',
    hide: 'Скрыть',
    unknown_error: 'Неизвестная ошибка, пожалуйста, попробуйте позже.',
    select: 'Выбрать',
    contact_us_action: 'Связаться с нами',
    /** UNTRANSLATED */
    description: 'Description',
    /** UNTRANSLATED */
    name: 'Name',
    /** UNTRANSLATED */
    add_field: 'Add {{field}}',
    /** UNTRANSLATED */
    create_field: 'Create {{field}}',
    /** UNTRANSLATED */
    edit_field: 'Edit {{field}}',
    /** UNTRANSLATED */
    delete_field: 'Delete {{field}}',
    coming_soon: 'Скоро',
    beta: 'Бета',
};
export default Object.freeze(general);
