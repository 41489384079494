const tenants = {
    title: '設定',
    description: 'テナントの設定を効率的に管理し、ドメインをカスタマイズします。',
    tabs: {
        settings: '設定',
        domains: 'ドメイン',
        subscription: 'プランと請求',
        billing_history: '請求履歴',
    },
    settings: {
        title: '設定',
        description: 'テナント名を設定し、データがホストされている地域と環境のタグを表示します。',
        tenant_id: 'テナントID',
        tenant_name: 'テナント名',
        /** UNTRANSLATED */
        tenant_region: 'Data hosted region',
        /** UNTRANSLATED */
        tenant_region_tip: 'Your tenant resources are hosted in {{region}}. <a>Learn more</a>',
        environment_tag: '環境タグ',
        environment_tag_description: 'タグはサービスを変更しません。単にさまざまな環境を区別するためのガイドです。',
        environment_tag_development: '開発',
        environment_tag_staging: 'ステージング',
        environment_tag_production: '本番',
        /** UNTRANSLATED */
        development_description: 'Development environment is mainly used for testing and include all pro features but have watermarks in the sign in experience. <a>Learn more</a>',
        tenant_info_saved: 'テナント情報は正常に保存されました。',
    },
    full_env_tag: {
        /** UNTRANSLATED */
        development: 'Development',
        /** UNTRANSLATED */
        production: 'Production',
    },
    deletion_card: {
        title: '削除',
        tenant_deletion: 'テナントの削除',
        tenant_deletion_description: 'テナントの削除は、関連するすべてのユーザーデータと設定の永久的な削除につながります。十分に注意して操作してください。',
        tenant_deletion_button: 'テナントを削除する',
    },
    create_modal: {
        title: 'テナントを作成する',
        subtitle: 'リソースとユーザーを分離するには、新しいテナントを作成します。',
        /** UNTRANSLATED */
        subtitle_with_region: 'Create a new tenant to separate resources and users. Region and environment tags can’t be modified after creation.',
        /** UNTRANSLATED */
        tenant_usage_purpose: 'What do you want to use this tenant for?',
        /** UNTRANSLATED */
        development_description: 'Development environment is mainly used for testing and should not use in production environment.',
        /** UNTRANSLATED */
        development_hint: 'Development environment is mainly used for testing and should not use in production environment.',
        /** UNTRANSLATED */
        production_description: 'Production is where live software is used by end-users and may require a paid subscription.',
        /** UNTRANSLATED */
        available_plan: 'Available plan:',
        create_button: 'テナントを作成する',
        tenant_name_placeholder: '私のテナント',
    },
    notification: {
        /** UNTRANSLATED */
        allow_pro_features_title: 'You can now access <span>all features of Logto Pro</span> in your development tenant!',
        /** UNTRANSLATED */
        allow_pro_features_description: "It's completely free, with no trial period – forever!",
        /** UNTRANSLATED */
        explore_all_features: 'Explore all features',
        /** UNTRANSLATED */
        impact_title: 'Does this have any impact on me?',
        /** UNTRANSLATED */
        staging_env_hint: 'Your tenant label has been updated from "Staging" to "Production," but this change will not impact your current setup.',
        /** UNTRANSLATED */
        paid_tenant_hint_1: 'As you subscribe to the Logto Hobby plan, your previous "dev" tenant tag will switch to "prod", and this won\'t affect your existing setup.',
        /** UNTRANSLATED */
        paid_tenant_hint_2: "If you're still in the development stage, you can create a new development tenant to access more pro features.",
        /** UNTRANSLATED */
        paid_tenant_hint_3: "If you're in the production stage, or a production environment, you still need to subscribe to a specific plan so there's nothing you need to do at this moment.",
        /** UNTRANSLATED */
        paid_tenant_hint_4: "Don't hesitate to reach out if you require help! Thank you for choosing Logto!",
    },
    delete_modal: {
        title: 'テナントを削除します',
        description_line1: '"<span>{{name}}</span>" というテナント ("<span>{{tag}}</span>" の環境タグを持つ) を削除してもよろしいですか？ このアクションは元に戻せません。これにより、すべてのデータとアカウント情報が永久に削除されます。',
        description_line2: 'アカウントの削除前に、お手伝いできるかもしれません。 <span><a>メールでお問い合わせください</a></span>。',
        description_line3: '続行する場合は、テナント名 "<span>{{name}}</span>" を入力して確認してください。',
        delete_button: '完全に削除する',
        cannot_delete_title: 'このテナントは削除できません',
        cannot_delete_description: '申し訳ありませんが、現時点ではこのテナントを削除できません。無料プランに登録しており、未払いの請求がないことを確認してください。',
    },
    tenant_landing_page: {
        title: 'まだテナントを作成していません',
        description: 'Logto でプロジェクトを設定するには、新しいテナントを作成してください。ログアウトまたはアカウントを削除する必要がある場合は、右上隅のアバターボタンをクリックしてください。',
        create_tenant_button: 'テナントを作成',
    },
    status: {
        mau_exceeded: 'MAUの制限を超えました',
        suspended: '一時停止中',
        overdue: '期限切れ',
    },
    tenant_suspended_page: {
        title: 'テナントが一時停止されました。アクセスを復元するにはお問い合わせください。',
        description_1: '誠に申し訳ありませんが、ご利用のテナントアカウントが一時的に停止されました。MAU制限を超えた、支払いの遅延、その他の不正な操作などが原因です。',
        description_2: '詳細な説明や懸念事項がある場合、または機能を完全に復元しテナントをアンブロックする場合は、直ちにお問い合わせください。',
    },
    signing_keys: {
        /** UNTRANSLATED */
        title: 'SIGNING KEYS',
        /** UNTRANSLATED */
        description: 'Securely manage signing keys in your tenant.',
        type: {
            /** UNTRANSLATED */
            private_key: 'OIDC private keys',
            /** UNTRANSLATED */
            cookie_key: 'OIDC cookie keys',
        },
        /** UNTRANSLATED */
        private_keys_in_use: 'Private keys in use',
        /** UNTRANSLATED */
        cookie_keys_in_use: 'Cookie keys in use',
        /** UNTRANSLATED */
        rotate_private_keys: 'Rotate private keys',
        /** UNTRANSLATED */
        rotate_cookie_keys: 'Rotate cookie keys',
        /** UNTRANSLATED */
        rotate_private_keys_description: 'This action will create a new private signing key, rotate the current key, and remove your previous key. Your JWT tokens signed with the current key will remain valid until deletion or another round of rotation.',
        /** UNTRANSLATED */
        rotate_cookie_keys_description: 'This action will create a new cookie key, rotate the current key, and remove your previous key. Your cookies with the current key will remain valid until deletion or another round of rotation.',
        /** UNTRANSLATED */
        select_private_key_algorithm: 'Select signing key algorithm for the new private key',
        /** UNTRANSLATED */
        rotate_button: 'Rotate',
        table_column: {
            /** UNTRANSLATED */
            id: 'ID',
            /** UNTRANSLATED */
            status: 'Status',
            /** UNTRANSLATED */
            algorithm: 'Signing key algorithm',
        },
        status: {
            /** UNTRANSLATED */
            current: 'Current',
            /** UNTRANSLATED */
            previous: 'Previous',
        },
        reminder: {
            /** UNTRANSLATED */
            rotate_private_key: 'Are you sure you want to rotate the <strong>OIDC private keys</strong>? New issued JWT tokens will be signed by the new key. Existing JWT tokens stay valid until you rotate again.',
            /** UNTRANSLATED */
            rotate_cookie_key: 'Are you sure you want to rotate the <strong>OIDC cookie keys</strong>? New cookies generated in sign-in sessions will be signed by the new cookie key. Existing cookies stay valid until you rotate again.',
            /** UNTRANSLATED */
            delete_private_key: 'Are you sure you want to delete the <strong>OIDC private key</strong>? Existing JWT tokens signed with this private signing key will no longer be valid.',
            /** UNTRANSLATED */
            delete_cookie_key: 'Are you sure you want to delete the <strong>OIDC cookie key</strong>? Older sign-in sessions with cookies signed with this cookie key will no longer be valid. A re-authentication is required for these users.',
        },
        messages: {
            /** UNTRANSLATED */
            rotate_key_success: 'Signing keys rotated successfully.',
            /** UNTRANSLATED */
            delete_key_success: 'Key deleted successfully.',
        },
    },
};
export default Object.freeze(tenants);
