const session = {
    not_found: 'Session non trouvée. Veuillez revenir en arrière et vous connecter à nouveau.',
    invalid_credentials: "Informations d'identification non valides. Veuillez vérifier votre saisie.",
    invalid_sign_in_method: "La méthode de connexion actuelle n'est pas disponible.",
    invalid_connector_id: "Impossible de trouver un connecteur disponible avec l'id {{connectorId}}.",
    insufficient_info: "Informations d'identification insuffisantes",
    connector_id_mismatch: "Le connectorId ne correspond pas à l'enregistrement de la session.",
    connector_session_not_found: "La session du connecteur n'a pas été trouvée. Veuillez revenir en arrière et vous connecter à nouveau.",
    verification_session_not_found: "La vérification n'a pas abouti. Redémarrez le processus de vérification et réessayez.",
    verification_expired: 'La connexion a expiré. Vérifiez à nouveau pour assurer la sécurité de votre compte.',
    /** UNTRANSLATED */
    verification_blocked_too_many_attempts: 'Too many attempts in a short time. Please try again {{relativeTime}}.',
    unauthorized: "Veuillez vous enregistrer d'abord.",
    unsupported_prompt_name: "Nom d'invite non supporté.",
    forgot_password_not_enabled: "La fonctionnalité de réinitialisation de mot de passe n'est pas activée.",
    verification_failed: "La vérification n'a pas réussi. Veuillez recommencer le processus de vérification.",
    connector_validation_session_not_found: 'La session de validation de jeton de connecteur est introuvable.',
    identifier_not_found: 'Identifiant utilisateur introuvable. Veuillez retourner en arrière et vous connecter à nouveau.',
    interaction_not_found: "Session d'interaction introuvable. Veuillez retourner en arrière et recommencer la session.",
    /** UNTRANSLATED */
    not_supported_for_forgot_password: 'This operation is not supported for forgot password.',
    mfa: {
        /** UNTRANSLATED */
        require_mfa_verification: 'Mfa verification is required to sign in.',
        /** UNTRANSLATED */
        mfa_sign_in_only: 'Mfa is only available for sign-in interaction.',
        /** UNTRANSLATED */
        pending_info_not_found: 'Pending MFA info not found, please initiate MFA first.',
        /** UNTRANSLATED */
        invalid_totp_code: 'Invalid TOTP code.',
        /** UNTRANSLATED */
        webauthn_verification_failed: 'WebAuthn verification failed.',
        /** UNTRANSLATED */
        webauthn_verification_not_found: 'WebAuthn verification not found.',
        /** UNTRANSLATED */
        bind_mfa_existed: 'MFA already exists.',
        /** UNTRANSLATED */
        backup_code_can_not_be_alone: 'Backup code can not be the only MFA.',
        /** UNTRANSLATED */
        backup_code_required: 'Backup code is required.',
        /** UNTRANSLATED */
        invalid_backup_code: 'Invalid backup code.',
        /** UNTRANSLATED */
        mfa_policy_not_user_controlled: 'MFA policy is not user controlled.',
    },
};
export default Object.freeze(session);
