const webhooks = {
    page_title: 'Webhooks',
    title: 'Webhooks',
    subtitle: 'Crea webhooks para recibir de manera fácil actualizaciones en tiempo real sobre eventos específicos.',
    create: 'Crear Webhook',
    events: {
        post_register: 'Crear nueva cuenta',
        post_sign_in: 'Iniciar sesión',
        post_reset_password: 'Restablecer contraseña',
    },
    table: {
        name: 'Nombre',
        events: 'Eventos',
        success_rate: 'Tasa de éxito (24 h)',
        requests: 'Solicitudes (24 h)',
    },
    placeholder: {
        title: 'Webhook',
        description: 'Crea un webhook para recibir actualizaciones en tiempo real a través de solicitudes POST a tu URL de punto final. Mantente informado y toma medidas inmediatas en eventos como "Crear cuenta", "Iniciar sesión" y "Restablecer contraseña".',
        create_webhook: 'Crear Webhook',
    },
    create_form: {
        title: 'Crear Webhook',
        subtitle: 'Agregue el Webhook para enviar una solicitud POST a la URL de punto final con detalles de cualquier evento de usuario.',
        events: 'Eventos',
        events_description: 'Seleccione los eventos desencadenantes que Logto enviará la solicitud POST.',
        name: 'Nombre',
        name_placeholder: 'Ingrese el nombre del webhook',
        endpoint_url: 'URL de punto final',
        endpoint_url_placeholder: 'https://su.webhook.endpoint.url',
        /** UNTRANSLATED */
        endpoint_url_tip: 'Enter the URL of your endpoint where a webhook’s payload is sent to when the event occurs.',
        create_webhook: 'Crear webhook',
        missing_event_error: 'Debe seleccionar al menos un evento.',
    },
    webhook_created: 'El webhook {{name}} se ha creado correctamente.',
};
export default Object.freeze(webhooks);
