const tabs = {
    get_started: 'Commencez',
    dashboard: 'Tableau de bord',
    applications: 'Applications',
    api_resources: 'Ressources API',
    sign_in_experience: 'Expérience de connexion',
    connectors: 'Connecteurs',
    webhooks: 'Webhooks',
    /** UNTRANSLATED */
    organizations: 'Organizations',
    users: 'Gestion des utilisateurs',
    audit_logs: "Journaux d'audit",
    roles: 'Roles',
    docs: 'Documentation',
    tenant_settings: 'Paramètres',
    /** UNTRANSLATED */
    mfa: 'Multi-factor auth',
};
export default Object.freeze(tabs);
